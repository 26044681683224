import jetjs from "@jetjs/jetjs";
import { register as registerOnPlugin } from "@jetjs/jetjs-plugin-on";
import { register as registerAlertPlugin } from "@jetjs/jetjs-plugin-alert";

jetjs.init({
  threadPoolWorkerSrc: "/js/jetjs-worker.min.js"
});

// hello world plugin for our getting started guide
jetjs.registerPlugin("helloWorld", (ele: HTMLElement) => {
  ele.addEventListener("click", () => {
    alert("Hello World");
  });
});


registerOnPlugin(jetjs);
registerAlertPlugin(jetjs);

jetjs.registerPluginChain("helloWorldPluginChain", (opts) => {
  return {
    plugin: "onClick",
    parameter: {},
    pipe: {
      plugin: "alert",
      parameter: {
        message: opts.message || "Hello World - from plugin chain"
      }
    }
  };
});

jetjs.searchAndRun(document.body);
